@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Base styles */
body {
  background-color: #f3f4f6; /* equivalente a bg-gray-100 */
  color: #1f2937; /* equivalente a text-gray-800 */
  transition: background-color 0.3s, color 0.3s;
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Dark mode styles */
.dark body {
  background-color: #111827; /* equivalente a bg-gray-900 */
  color: #ffee00; /* Giallo per il testo principale in modalità scura */
}

.dark a {
  color: #ffee00; /* equivalente a text-yellow-300 */
}

.dark a:hover {
  color: #ffffff; /* Bianco per hover in modalità scura */
}

.dark h1, .dark h2, .dark h3, .dark h4 {
  color: #fde047; /* equivalente a text-yellow-300 */
}

.dark .bg-white {
  background-color: #1f2937; /* equivalente a bg-gray-800 */
}

.dark .text-gray-700, .dark .text-aeslis-blue, .dark .text-blue-700 {
  color: #fde047; /* Giallo per miglior contrasto in modalità scura */
}

.dark .bg-aeslis-blue, .dark .bg-blue-700 {
  background-color: #1f2937; /* equivalente a bg-gray-800 */
}

/* Navigation styles */
.nav-link {
  color: #ffffff; /* Bianco per il testo del menu */
  transition: color 0.3s;
}

.nav-link:hover {
  color: #ffee00; /* Giallo per hover */
}

.nav-link.active {
  color: #ffee00; /* Giallo per il link attivo */
  font-weight: 600;
}

.dark .nav-link {
  color: #ffee00; /* Giallo in modalità scura */
}

.dark .nav-link:hover {
  color: #ffffff; /* Bianco per hover in modalità scura */
}

.dark .nav-link.active {
  color: #ffffff; /* Bianco per il link attivo in modalità scura */
  font-weight: 600;
}

/* CTA Button styles */
.cta-button {
  background-color: #D4AF37; /* Oro per AESLIS */
  color: #1f2937; /* Testo scuro */
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
  background-color: #B8860B; /* Oro scuro per hover */
  color: #ffffff; /* Testo bianco per hover */
}

.dark .cta-button {
  background-color: #fde047; /* Giallo più chiaro in modalità scura */
  color: #1f2937; /* Testo scuro per contrasto */
}

.dark .cta-button:hover {
  background-color: #facc15; /* Giallo leggermente più scuro per hover in modalità scura */
  color: #1f2937; /* Mantiene il testo scuro per contrasto */
}

/* Accessibility Controls */
@keyframes fade-in {
  from { 
    opacity: 0; 
    transform: scale(0.95) translateY(10px); 
  }
  to { 
    opacity: 1; 
    transform: scale(1) translateY(0); 
  }
}

.animate-fade-in {
  animation: fade-in 0.3s ease-out forwards;
}

@keyframes pulse {
  0%, 100% { 
    opacity: 1;
    transform: scale(1); 
  }
  50% { 
    opacity: 0.85;
    transform: scale(1.05); 
  }
}

.animate-pulse {
  animation: pulse 2s ease-in-out infinite;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.animate-slide-vertical {
  animation: slide-down 0.3s ease-out forwards;
}

.accessibility-controls[data-closing="true"] .animate-slide-vertical {
  animation: slide-up 0.3s ease-out forwards;
}

.delay-100 {
  animation-delay: 0.1s;
}

.delay-200 {
  animation-delay: 0.2s;
}

/* Desktop Accessibility Controls */
.accessibility-controls {
  position: fixed;
  right: 20px;
  top: 70%; /* Spostato ancora più in basso */
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  z-index: 50;
}

.accessibility-button {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 9999px;
  transition: all 200ms ease-in-out;
  background-color: rgb(250 204 21);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.accessibility-button svg {
  width: 1.25rem;
  height: 1.25rem;
  color: black;
}

.accessibility-button.dark svg {
  color: black;
}

.accessibility-button:hover {
  background-color: rgb(253 224 71);
  transform: scale(1.05);
}

.accessibility-button.dark {
  background-color: rgb(234 179 8);
}

.accessibility-button:hover.dark {
  background-color: rgb(250 204 21);
}

/* Mobile Accessibility Controls */
@media (max-width: 768px) {
  .accessibility-controls {
    position: fixed;
    bottom: 20px;
    right: 20px;
    top: auto;
    transform: none;
    flex-direction: row;
    gap: 0.5rem;
    background-color: transparent; /* Rimosso sfondo */
  }

  .accessibility-floating-button {
    width: 3rem;
    height: 3rem;
    background-color: rgb(250 204 21);
    border-radius: 9999px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 200ms ease-in-out;
  }

  .accessibility-floating-button:hover {
    transform: scale(1.1);
  }

  .accessibility-floating-button svg {
    width: 1.5rem;
    height: 1.5rem;
    color: black;
  }

  .accessibility-menu {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    padding: 1rem;
    min-width: 280px;
    max-width: 320px;
  }

  .accessibility-menu.dark {
    background-color: rgb(31 41 55);
  }

  .accessibility-menu-button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: rgb(243 244 246);
    color: rgb(31 41 55);
    transition: all 200ms ease-in-out;
  }

  .accessibility-menu-button.dark {
    background-color: rgb(55 65 81);
    color: white;
  }

  .accessibility-menu-button:hover {
    background-color: rgb(229 231 235);
  }

  .accessibility-menu-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
/* Social icons */
.social-icon {
  transition: color 0.3s;
}

.dark .social-icon:hover {
  color: #ffffff;
}

/* Additional utility classes */
.text-aeslis-blue {
  color: #1E40AF; /* Blu AESLIS */
}

.bg-aeslis-blue {
  background-color: #1E40AF; /* Blu AESLIS */
}

.text-aeslis-gold {
  color: #D4AF37; /* Oro AESLIS */
}

.bg-aeslis-gold {
  background-color: #D4AF37; /* Oro AESLIS */
}

/* Responsive design */
@media (max-width: 768px) {
  .accessibility-controls {
    top: auto;
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    flex-direction: row;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.95);
  }

  .dark .accessibility-controls {
    background-color: rgba(31, 41, 55, 0.95);
  }

  .accessibility-controls::before {
    content: "Accessibilità";
    display: block;
    margin-right: 0.5rem;
    font-weight: bold;
  }
}

/* Additional styles for better readability and contrast */
.dark p {
  color: #fef3c7; /* Giallo chiaro per il testo dei paragrafi in modalità scura */
}

.dark .bg-white p {
  color: #e5e7eb; /* Grigio chiaro per il testo dei paragrafi su sfondo scuro in modalità scura */
}

/* Ensure form inputs are readable in dark mode */
.dark input, .dark textarea {
  background-color: #374151; /* Grigio scuro per input in modalità scura */
  color: #ffffff;
  border-color: #4b5563;
}

.dark input::placeholder, .dark textarea::placeholder {
  color: #9ca3af;
}

/* Ensure table readability in dark mode */
.dark table {
  border-color: #4b5563;
}

.dark th, .dark td {
  border-color: #4b5563;
}

.dark thead {
  background-color: #1f2937;
}

.dark tbody tr:nth-child(even) {
  background-color: #283548;
}

